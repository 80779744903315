//
// Updates the opportunity template name selections when the opportunity skill
// category is changed.
//
// To use this controller:
//
// <form data-controller="opportunity-templates-filter">
//   <select name="first-select" data-action="change->opportunity-templates-filter#filter"><option></option></select>
//   <select name="updated-select" data-opportunity-templates-filter-target="replacement"><option></option></select>
// </form>
//
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "replacement"
  ]

  filter(event) {
    const categoryEl = event.currentTarget;
    const skillCategoryId = categoryEl.selectedOptions[0].value;

    this.replace(skillCategoryId);
  }

  replace(skillCategoryId) {
    fetch(`/organizations/opportunity_templates?skill_category_id=${skillCategoryId}`)
      .then(response => response.text())
      .then(html => this.replacementTarget.innerHTML = html)
  }
}
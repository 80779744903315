//
// Show a confirmation modal window before submitting a form.
//
// To use this controller:
//
// <div data-controller="confirm">
//   <form data-action="submit->confirm#show">
//     ...
//   </form>
//   <%= render layout: "shared/modal", locals: { data: { "confirm-target": "modal" } } do %>
//     ...
//     <a data-action="click->confirm#accept">Submit</a>
//     <a data-action="click->confirm#reject">Cancel</a>
//   <% end %>
// </div>
//
import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "modal"
  ]

  form = undefined;
  submitting = false; // the form is actively being submitted

  //
  // The primary application area
  //
  get mainContentEl(){
    return document.getElementsByClassName("js-main-content")[0];
  }

  //
  // Show the modal window
  //
  show(event) {
    if (!this.submitting) {
      this._stopDefault(event);
      this.form = event.currentTarget;
      this._disablePageScrolling();
      this.modalTarget.classList.remove('hidden')
    }
  }

  //
  // Hide the modal window
  //
  hide() {
    this._enablePageScrolling();
    this.modalTarget.classList.add('hidden')
  }

  //
  // Accept the confirmation
  //
  accept(event) {
    this._stopDefault(event);
    this.submitting = true;
    Rails.fire(this.form, 'submit');
    this.hide();
  }

  //
  // Reject (cancel) the confirmation
  //
  reject(event) {
    this._stopDefault(event);
    this.submitting = false;
    this.hide();
  }

  //
  // Private:
  // Prevent events from bubbling
  //
  _stopDefault(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  //
  // Private:
  // Prevent the page beneath the opacity overlay from scrolling
  //
  // Note: this also fixes a Safari z-index bug
  //
  _disablePageScrolling(){
    if(this.mainContentEl){
      this.mainContentEl.classList.remove("overflow-y-auto");
    }
  }

  //
  // Private:
  // Allow the page beneath the opacity overlay to scroll
  //
  _enablePageScrolling(){
    if(this.mainContentEl){
      this.mainContentEl.classList.add("overflow-y-auto");
    }
  }
}

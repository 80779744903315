//
// Show and hide a content area when an action happens (like a link click)
//
// To use this controller:
//
// <div data-controller="accordion">
//  <a href="#" data-accordion-target="trigger" data-action="click->accordion#toggle" data-accordion-expanded-text="Collapse Me" data-accordion-collapsed-text="Shrink Me" />
//
//  <div data-accordion-target="content">
//    You can show and hide me!
//  </div>
// </div>
//
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "trigger", "content" ]

  connect() {
    this.contentTarget.classList.contains('hidden') ? this.hide() : this.show();
  }

  toggle(event) {
    this.contentTarget.classList.contains('hidden') ? this.show() : this.hide();
  }

  show(event) {
    this._stopDefault(event);
    this.triggerTarget.innerText = this.triggerTarget.dataset.accordionExpandedText;
    this.contentTarget.classList.remove('hidden');
  }

  hide(event) {
    this._stopDefault(event);
    this.triggerTarget.innerText = this.triggerTarget.dataset.accordionCollapsedText;
    this.contentTarget.classList.add('hidden');
  }

  //
  // Private:
  // Prevent events from bubbling
  //
  _stopDefault(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}

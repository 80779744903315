// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Rails UJS
import Rails from "@rails/ujs"
global.Rails = Rails;

import Turbo from "@hotwired/turbo-rails"
import 'alpine-hotwire-turbo-adapter'

// Stimulus
require("controllers")

// Tailwind CSS
import "stylesheets/application"
import "@fortawesome/fontawesome-free/css/all"

Rails.start()

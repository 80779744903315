//
// Clear a text element if a checkbox is unchecked
//
// To use this controller:
//
//   <input type='checkbox' data-controller='clear-field', data-action='click->clear-field#clear' data-clear-field-connected-field-id-value='the-dom-id-of-field-to-clear' />
//   <input type='text' value='will be cleared when checkbox is unchecked' />
//
import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    connectedFieldId: String
  }

  get connectedField(){
    return document.getElementById(this.connectedFieldIdValue);
  }

  connect(){
    this.clear();
  }

  clear(event) {
    if(!this.connectedField) { return };

    if(!this.element.checked) {
      this.connectedField.value = '';
      this.connectedField.readOnly = true;
    } else {
      this.connectedField.readOnly = false;
    }
  }
}

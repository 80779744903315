//
// Change the browser url when using turboframes
//
// By default, when you have a link or a form that has a turboframe target, the
// content will get updated in the target, but the browser url will not get
// updated.
//
// Note: This might get pulled into turbo if this gets merged:
// https://github.com/hotwired/turbo/issues/50
//
// To use this controller:
//
// <turbo-frame data-controller="turbo-frame-history" id="my-target-turbo-frame">
//   <p>This is the target turboframe content area that gets replaced!</p>
// </turbo-frame>
//
import { navigator } from "@hotwired/turbo"
import { Controller } from "stimulus"
import { useMutation } from "stimulus-use"

export default class extends Controller {
  connect() {
    useMutation(this, { attributes: true })
  }

  //
  // If target `src` changes, then push the new url onto the history stack
  //
  mutate(entries) {
    entries.forEach((mutation) => {
      if (mutation.type === "attributes" && mutation.attributeName === "src") {
        const src = this.element.getAttribute("src")
        if (src)
          navigator.history.push(new URL(src))
      }
    })
  }
}

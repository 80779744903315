//
// Mark a model for destruction when a form is saved
//
import { Controller } from "stimulus"

export default class extends Controller {
  //
  // find first input field wihtin controller element with
  // `__destroy` in its name
  //
  get destructionField() {
    return Array.prototype.slice.call(this.element.querySelectorAll('input')).find(function (el) {
      return el.name.match('__destroy');
    });
  }

  mark(event) {
    this._stopDefault();
    this.destructionField.value = true;
    this.element.classList.add('marked-for-destruction');
  }

  //
  // Private:
  // Prevent events from bubbling
  //
  _stopDefault(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}

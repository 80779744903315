//
// Calculate the height of the browser window and set it as `--vh` property
//
// Using `100vh` as the full height of a browser window does not take into
// account any mobile browser toolbars which will begin to clip readable text.
// There are currently only a few options to handle this, but the one that
// emcompasses the most edge cases is using javascript to calculate and set a
// `--vh` property which tailwind.config.js will use to calculate full screen
// height.
//
// https://www.markusantonwolf.com/en/articles/solution-to-the-mobile-viewport-height-issue-with-tailwind-css
//
// To use this controller, ensure that tailwind.config.js has been changed to use the `--vh` property and then:
//
//   <body data-controller='mobile-height'>
//     ...
//   </body>
//
import { Controller } from "stimulus";

export default class extends Controller {
  initialize() {
    this.setViewHeight();
    window.addEventListener('resize', () => {
      this.setViewHeight();
    })
  }

  setViewHeight() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
}

//
// Adds a `checked` css class to a parent object when a descendant element is checked.
//
// This is useful for applying proper page styles when nesting a radio button
// form object within a <label> element and when the radio button is selected,
// adding a `checked` css class to the label
//
// To use this controller:
//
// <form>
//   <label data-controller="parental-checked" data-parental-checked_target="parent">
//    Click me!
//    <input type="radio" data-parental-checked-target="selector" data-action="change->parental-checkmark#mark" />
//   </label>
// </form>
//
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "parent",
    "selector"
  ]

  initialize() {
    this.mark();
  }

  mark() {
    if(this.selectorTarget.checked) {
      this.parentTarget.classList.add('checked');
      this._clearOthers();
    } else {
      this._clear();
    }
  }

  _clear() {
    this.parentTarget.classList.remove("checked");
  }

  // since only radio can be selected at a time,
  // clear checked status from other radios
  _clearOthers() {
    if(this.selectorTarget && this.selectorTarget.type === "radio") {
      const radios = document.querySelectorAll(`input[name='${this.selectorTarget.name}'`);
      radios.forEach(function(radioEl){
        if(radioEl.id != this.selectorTarget.id) {
          radioEl.closest("[data-parental-checked-target=parent]").classList.remove("checked")
        }
      }.bind(this))
    }
  }
}

//
// Allow auto-submitting a form from a form element dom event.
//
// For instance, this is useful for submitting a form when a selectbox changes.
//
// To use this controller:
//
// <form data-controller="auto-submit">
//    <h1>Changing the selectbox option will submit the form</h1>
//    <select action="/" method="post" data-action="change->auto-submit#submit">
//      <option>Red</option>
//      <option>Green</option>
//    </select>
// </form>
//
// Notes:
// * The stimulus controller does not need defined on the form element itself.
//   It can be defined on any element within the form.
//
// * If you have issues with the form automatically submitting, try using a native
//   javascript submit, by changing your form to:
//
//     <form data-controller="auto-submit" data-auto-submit-native="true">
//
import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  get form() {
    return this.element.closest('form');
  }

  submit(event) {
    if(this.data.get("native")){
      // do a standard javascript submit
      this.form.submit();
    } else {
      // use a bubbled submit event
      Rails.fire(this.form, 'submit');
    }
  }
}

//
// Copy the innerText of a DOM element onto the user's clipboard
//
// To use this controller:
//
//   <div data-controller='clipboard'>
//    <textarea data-clipboard-target='source' />
//    <button data-clipboard-target='button' data-clipboard-action='clipboard#copy' />
//  </div>
//
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "source", "button" ]

  copy(event) {
    let currentText = this.buttonTarget.innerText
    event.preventDefault()

    this.sourceTarget.select()
    document.execCommand("copy")

    window.getSelection().removeAllRanges()
    this.buttonTarget.focus()
    this.buttonTarget.innerText = 'Copied to clipboard!'
    setTimeout(function() {
      this.buttonTarget.innerText = currentText
    }.bind(this), 750)
  }
}
